<template>
  <b-row class="">
    <b-col cols="12">
      <b-row>
        <b-col class="flex-grow-1 overflow-hidden">
          <b-row>
            <b-col
              cols="12"
              class="mt-16"
            >
              <h1 class="hp-mb-0">
                Plataforma Inspector
              </h1>
            </b-col>

            <!-- <b-col
              cols="12"
              class="mt-2"
            >
              <section class="text-center">
                <img
                  :src="require('@/assets/img/app/logo/logo_fluxsolar_xl.png')"
                  alt="fluxsolar logo"
                  width="30%"
                >
              </section>
            </b-col> -->

            <!-- <b-col cols="12">
              <b-row>
                <b-col cols="6" md="4" class="mb-32">
                  <feature-card
                    title="Ultimos proyectos"
                    titleIcon='<svg class="hp-text-color-success-1" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" > <path fill="currentColor" d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81v8.37C2 19.83 4.17 22 7.81 22h8.37c3.64 0 5.81-2.17 5.81-5.81V7.81C22 4.17 19.83 2 16.19 2zm1.06 10.33c0 .41-.34.75-.75.75s-.75-.34-.75-.75V9.31l-7.72 7.72c-.15.15-.34.22-.53.22s-.38-.07-.53-.22a.754.754 0 010-1.06l7.72-7.72h-3.02c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4.83c.41 0 .75.34.75.75v4.83z" ></path> </svg>'
                    date="April 2022"
                    price="30"
                  />
                </b-col>

                <b-col cols="6" md="4" class="mb-32">
                  <feature-card
                    title="Ventas ingresadas"
                    titleIcon='<svg class="hp-text-color-danger-1" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"><path fill="currentColor" d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81v8.37C2 19.83 4.17 22 7.81 22h8.37c3.64 0 5.81-2.17 5.81-5.81V7.81C22 4.17 19.83 2 16.19 2zm1.34 5.53l-7.72 7.72h3.02c.41 0 .75.34.75.75s-.34.75-.75.75H8c-.41 0-.75-.34-.75-.75v-4.83c0-.41.34-.75.75-.75s.75.34.75.75v3.02l7.72-7.72c.15-.15.34-.22.53-.22s.38.07.53.22c.29.29.29.77 0 1.06z"></path></svg>'
                    date="April 2022"
                    price="$7,949"
                  />
                </b-col>

                <b-col cols="6" md="4" class="mb-32">
                  <feature-card title="Balance" date="April 2022" price="$5,129" />
                </b-col>
              </b-row>
            </b-col> -->
            <!-- <b-col cols="12">
              <balance-card />
            </b-col>
 -->
            <!-- <b-col cols="12">
              <history-card
                :list="[
                  {
                    avatar: 'memoji/user-avatar-5.png',
                    avatarBg: ' hp-bg-danger-4',
                    name: 'Edward Adams',
                    title: 'Product Designer',
                    price: '$ 7734.32',
                    number: '30432',
                    percent: '23.23%'
                  },
                  {
                    avatar: 'memoji/user-avatar-6.png',
                    avatarBg: ' hp-bg-info-4',
                    name: 'John Doe',
                    title: 'Product Designer',
                    price: '$ 7614.43',
                    number: '949',
                    percent: '19.03%'
                  },
                  {
                    avatar: 'memoji/user-avatar-7.png',
                    avatarBg: ' hp-bg-warning-4',
                    name: 'Fazıl Say',
                    title: 'Product Designer',
                    price: '$ 6789.19',
                    number: '732',
                    percent: '13.98%'
                  }
                ]"
              />
            </b-col> -->
          </b-row>
        </b-col>

        <!-- <b-col class="hp-flex-none w-auto hp-dashboard-line px-0">
          <div class="hp-bg-black-40 hp-bg-dark-80 h-100 mx-24" style="width: 1px"></div>
        </b-col> -->
        <!-- <b-col class="hp-analytics-col-2">
          <b-row>
            <b-col cols="12" class="mb-32">
              <list-card
                title="Recent Activities"
                date="05 Dec 2021"
                :list="[
                  {
                    img: 'dashboard/zendesk-logo.svg',
                    title: 'Zendesk',
                    date: '05 Dec 2021',
                    price: '$ 500.00'
                  },
                  {
                    img: 'dashboard/sales-force-logo.svg',
                    title: 'Sales Force',
                    date: '24 Dec 2021',
                    price: '$ 337.00'
                  },
                  {
                    img: 'dashboard/apple-logo.svg',
                    title: 'Apple',
                    date: '29 Dec 2021',
                    price: '$ 320.67'
                  },
                  {
                    img: 'dashboard/google-logo.svg',
                    title: 'Google Inc',
                    date: '29 Dec 2021',
                    price: '$ 127.00'
                  },
                  {
                    img: 'dashboard/virgin-logo.svg',
                    title: 'Virgin Media',
                    date: '29 Dec 2021',
                    price: '$ 28.00'
                  }
                ]"
              />
            </b-col>

            <b-col cols="12" class="mb-32">
              <list-card
                title="Upcoming Payments"
                date="05 Dec 2021"
                :list="[
                  {
                    img: 'dashboard/zendesk-logo.svg',
                    title: 'Zendesk',
                    date: '05 Dec 2021',
                    price: '$ 500.00'
                  },
                  {
                    img: 'dashboard/sales-force-logo.svg',
                    title: 'Sales Force',
                    date: '24 Dec 2021',
                    price: '$ 337.00'
                  },
                  {
                    img: 'dashboard/virgin-logo.svg',
                    title: 'Virgin Media',
                    date: '29 Dec 2021',
                    price: '$ 28.00'
                  }
                ]"
              />
            </b-col>
          </b-row>
        </b-col> -->
      </b-row>
    </b-col>

    <b-col cols="12">
      <filters
        class="mb-24"
        :filters="filters"
        @update-filters="updateFilters"
      />
    </b-col>

    <b-col cols="12">
      <b-row>
        <b-col
          cols="12"
          xl="6"
          md="6"
          sm="12"
        >
          <h5 class="mb-20 text-black-80 hp-text-color-dark-30">
            Plantillas respondidas por día por Punto de Instalación
          </h5>

          <chart-bar-total />
        </b-col>

        <b-col
          cols="12"
          xl="6"
          md="6"
          sm="12"
        >
          <h5 class="mb-20 text-black-80 hp-text-color-dark-30">
            Plantillas respondidas por día por Punto de Instalación por Sección
          </h5>
          <chart-bar-section-day />
        </b-col>

        <b-col cols="12">
          <h5 class="mb-20 text-black-80 hp-text-color-dark-30">
            Últimas plantillas respondidas por Punto de Instalación
          </h5>

          <last-template-table />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import { BRow, BCol } from 'bootstrap-vue'

// import FeatureCard from './FeatureCard.vue'
// import BalanceCard from './BalanceCard.vue'
// import HistoryCard from './HistoryCard.vue'
// import ListCard from './ListCard.vue'

import Filters from './Filters.vue'
import ChartBarTotal from './ChartBarTotal.vue'
import ChartBarSectionDay from './ChartBarSectionDay.vue'
import LastTemplateTable from './LastTemplateTable.vue'

export default {
  components: {
    BRow,
    BCol,
    Filters,
    ChartBarTotal,
    ChartBarSectionDay,
    LastTemplateTable,

    // FeatureCard,
    // BalanceCard,
    // HistoryCard,
    // ListCard,
  },
  data() {
    return {
      filters: {
        since: null,
        until: null,
      },
    }
  },
  watch: {
    filters: {
      async handler(value) {
        await this.getGraphs({
          separate_by: 'day',
          since_date: value.since,
          until_date: value.until,
        })
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getSections()
    await this.getGraphs({
      separate_by: 'day',
      since_date: moment().subtract(6, 'days').format('YYYY/MM/DD'),
      until_date: moment().add(1, 'days').format('YYYY/MM/DD'),
    })
  },
  methods: {
    ...mapActions({
      getGraphs: 'dashboard/getGraphs',
      getSections: 'project/getSections',
    }),
    updateFilters(newFilters) {
      this.filters = newFilters
    },
  },
}
</script>
