<template>
  <div>
    <b-col
      cols="12"
      class="mb-32"
    >
      <b-row>
        <b-col
          cols="12"
          xl="4"
          md="4"
          sm="12"
        >
          <label for="datepicker-week">Filtrar datos por semana</label>
          <date-picker
            id="datepicker-week"
            v-model="week"
            placeholder="Selecciona una semana"
            class="w-100"
            type="week"
            :formatter="momentFormat"
            @change="onSelectWeek"
          />
        </b-col>
      </b-row>
    </b-col>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    DatePicker,
  },
  props: {
    list: {
      type: Array,
      required: false,
      default: () => [],
    },
    filters: {
      type: Object,
      required: false,
      default: () => ({
        since: null,
        until: null,
      }),
    },
  },
  data() {
    return {
      week: null,
      momentFormat: {
        stringify: date => {
          if (date) {
            const momentDate = moment(date)
            const weekStart = momentDate.clone().startOf('week')
            const weekEnd = momentDate.clone().endOf('week')
            return `${weekStart.format('DD/MM/yyyy')} - ${weekEnd.format('DD/MM/yyyy')}`
          }
          return ''
        },
        parse: value => (value ? moment(value, 'LL').toDate() : null),
        getWeek: date => {
          // a number
        },
      },
    }
  },
  methods: {
    onSelectWeek(date) {
      const momentDate = moment(date)
      const weekStart = momentDate.clone().startOf('week')
      const weekEnd = momentDate.clone().endOf('week').add(1, 'day')
      this.$emit('update-filters', {
        since: weekStart.format('YYYY/MM/DD'),
        until: weekEnd.format('YYYY/MM/DD'),
      })
    },
  },
}
</script>
